
import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo } from "@/components/Notification";
import { isSuper } from "@/store/getters";
import moment from "moment";
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default Vue.extend({
  name: "crm-leads-component",
  data() {
    return {
      tempLead: null,
      loadingD: false,
      dialogDelete: false,
      itemDelete: null,
    };
  },
  props: {
    data: Array,
    headers: String,
  },
  computed: {
    ...mapState("crmConfigurationsModule", ["loading"]),
    ...mapGetters(["isAdmin", "isSuper"]),
  },
  methods: {
    ...mapActions("crmConfigurationsModule", ["actAddDescription"]),

    prettyDateShowTo(date: any) {
      if (date === null || date === "") return "";

      return moment(date).format("MM/DD/YY, h:mm:ss a");
    },

    toDelete(item: any) {
      this.dialogDelete = true;
      this.itemDelete = item;
    },
    cancelDelete() {
      this.dialogDelete = false;
      this.itemDelete = null;
      this.loadingD = false;
    },

    deleteNote() {
      this.loadingD = true;
      const uuid = (this as any).itemDelete
        ? (this as any).itemDelete.uuid
        : null;
      getAPI
        .delete("/social/deleteNote/" + uuid)
        .then((res) => {
          notifyInfo("Note has been deleted ");
          this.$emit("deletedNote");
          this.cancelDelete();
        })
        .catch((error) => {
          this.loadingD = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
