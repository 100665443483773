<template>
  <div>
    <v-dialog v-model="dialogCall" max-width="400">
      <v-card>
        <v-toolbar dark color="primary">
          <h3>Edit Description</h3>
        </v-toolbar>
        <v-card-title primary-title> </v-card-title>
        <v-card-text>
          <v-textarea
            outlined
            name="input-7-4"
            label="DESCRIPTION"
            v-model="descriptioncall"
          ></v-textarea
        ></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cancelDialog">
            Cancel
          </v-btn>
          <v-btn color="green darken-1" text @click="addDescription">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-simple-table>
      <template>
        <thead>
          <tr>
            <th class="text-left">No.</th>
            <th class="text-left">Creation Date</th>
            <th class="text-left">Description</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody v-if="!loadingfilter">
          <tr v-for="(item, index) in calls" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ prettyDateShowTo(item.createAt) }}</td>
            <td width="180">
              {{
                item.leadCallDescription != undefined
                  ? item.leadCallDescription
                  : "-"
              }}
            </td>
            <td v-if="item.leadCallD == undefined">
              <v-btn
                icon
                class="ml-auto"
                color="primary"
                @click="OpenDialog(item.uuid, item.leadCallDescription)"
              >
                <v-icon small>mdi-pencil</v-icon> Edit</v-btn
              >
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <h4 class="ml-5 mt-2 mb-2">Loading calls...</h4>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import moment from "moment";
import Vue from "vue";
import { mapActions, mapState } from "vuex";
export default Vue.extend({
  name: "crm-leads-calls",
  data() {
    return {
      tempLead: null,
      descriptioncall: "",
      callUuid: "",
      dialogCall: false,
    };
  },
  computed: {
    ...mapState("crmConfigurationsModule", ["loading"]),
    ...mapState("crmCallCenterModule", ["callsLead", "loadingfilter"]),

    calls() {
      if (this.callsLead.length == 0) {
        return [];
      }
      const callss = this.callsLead;
      const sort = callss.sort(
        (a, b) => moment(b.createAt).unix() - moment(a.createAt).unix()
      );

      return sort;
    },
  },

  methods: {
    ...mapActions("crmConfigurationsModule", ["actAddDescription"]),
    formatDate(date) {
      if (!date) return null;
      const [year, month, data] = date.split("-");
      const day = data.substr(-20, 2);
      return `${day}/${month}/${year}`;
    },
    prettyDateShowTo(date) {
      if (date === null || date === "") return "";

      return moment(date).format("MM/DD/YY, h:mm:ss a");
    },

    OpenDialog(value, descrp) {
      this.descriptioncall = descrp;
      this.dialogCall = true;
      this.callUuid = value;
    },

    async addDescription() {
      await this.actAddDescription({
        leadCallDescription: this.descriptioncall,
        callUuid: this.callUuid,
      });
      this.cancelDialog();
      this.$emit("descripadd");
    },

    cancelDialog() {
      this.dialogCall = false;
      this.descriptioncall = "";
    },
  },
});
</script>
<style lang=""></style>
