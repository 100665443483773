<template>
  <v-menu
    v-model="showPopover"
    persistent
    content-class="rounded-xl"
    :close-on-content-click="false"
    :close-on-click="false"
    :nudge-width="230"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="makeCall()" icon v-bind="attrs" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="!fromcall"
              :color="external ? 'white' : 'black'"
              v-on="on"
              v-bind="attrs"
              >mdi-phone-dial</v-icon
            >
            <v-icon
              v-if="fromcall"
              :color="external ? 'white' : 'success'"
              v-on="on"
              v-bind="attrs"
              >mdi-phone-plus-outline</v-icon
            >
          </template>
          {{ $t("title") }}
        </v-tooltip>
      </v-btn>
    </template>

    <v-card class="aside-call white">
      <v-card-title class="headline primary white--text">
        {{ $t("title") }}
      </v-card-title>

      <v-window class="call-ui" v-model="callUi">
        <v-window-item>
          <v-layout column>
            <v-toolbar flat dense>
              <v-toolbar-title>
                {{ $t("crmCallCenter.markPhoneNumber") }}
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />

            <dialer class="mt-2" :value="number" @make-call="makeCall" />
          </v-layout>
        </v-window-item>

        {{/* Call in progress */}}
        <v-window-item>
          <v-layout column>
            <v-toolbar flat dense>
              <v-btn icon @click="callUi = 0">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-toolbar-title>
                {{ $t("callInProgress") }}
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />

            <call-in-progress
              :leadUuid="leadUuid"
              :number="numberPatient != undefined ? numberPatient : number"
              @hangup="hangup"
              @errorcall="errorcaller"
            />
          </v-layout>
        </v-window-item>
        <v-window-item>
          <v-layout column justify-center>
            <h4 class="d-flex justify-center">Calling to: {{ number }}</h4>
            <h5 :class="`${colors}--text`" class="d-flex justify-center">
              {{ $t(message) }}
            </h5>
            <template v-if="message != 'errorcall'">
              <div class="d-flex justify-center my-3">
                <v-progress-linear
                  color="green"
                  buffer-value="0"
                  stream
                ></v-progress-linear>
              </div>
            </template>

            <template v-if="message == 'errorcall'">
              <v-btn class="my-1" @click="makeCall" color="warning">
                <v-icon>mdi-phone-in-talk</v-icon> Retry</v-btn
              >
            </template>
            <v-btn class="my-1" @click="hangup" color="error">
              <v-icon>mdi-phone-hangup</v-icon> Cancel</v-btn
            >
          </v-layout>
        </v-window-item>
      </v-window>
    </v-card>
  </v-menu>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import Dialer from "@/components/Dialer/Dialer.vue";
import CallInProgress from "../CallInProgress.vue";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { getAPI } from "@/api/axios-base";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "btn-make-call",
  props: {
    external: Boolean,
    numberPatient: String,
    lead: String,
    fromcall: Boolean,
  },
  components: {
    Dialer,
    CallInProgress,
  },
  data() {
    return {
      showPopover: false,
      callUi: 0,
      number: "",
      leadUuid: "",
      message: "",
    };
  },
  computed: {
    ...mapGetters({ methodCall: "getMethodCall" }),

    colors() {
      switch (this.message) {
        case "callInProgress":
          return "green";

        case "errorcall":
          return "red";

        default:
          return "blue";
      }
    },
  },
  methods: {
    makeCall(number) {
      if (this.numberPatient == undefined && number == undefined) {
        this.callUi = 0;
      } else {
        this.callUi = 1;
        this.number = number;
        if (this.lead != undefined && this.lead != null && this.lead != "") {
          this.leadUuid = this.lead;
        }
      }
    },
    errorcaller(error) {
      this.number = "";
      this.callUi = 0;
      this.showPopover = false;
      let mess = error.response.data.message.toString();

      notifyError(error.response.data, `An error occurred: ${mess}`);
    },
    hangup() {
      this.number = "";
      this.callUi = 0;
      this.showPopover = false;
      notifyInfo(this.$t("crmCallCenter.notificationCallFinish"));
    },
  },
});
</script>

<i18n>
{
  "en": {
    "title": "Make a call",
    "errorcall":"Error in Call, Please Retry!!",
    "conect":"Conecting..."
  },
  "es": {
    "title": "Haz una llamada",
    "errorcall":"Error en la Llamada, vuelve a intentar!!",
    "conect":"Conectando..."
  }
}
</i18n>

<style lang="scss" scoped>
.aside-call {
  background-image: url("/img/drawer-bg.svg");
  background-position: bottom;
  background-size: 100%;
}
</style>
