<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-sheet class="my-2 mx-auto" elevation="8" height="340" max-width="1200">
    <div class="d-flex mx-2">
      <v-file-input
        v-model="files"
        class="blue--text"
        multiple
        prepend-icon="mdi-cloud-upload"
        color="info"
        label="Upload Files"
        show-size
        @change="setImages"
      >
        <template v-slot:selection="{}">
          <v-dialog v-model="dialog2" max-width="500px">
            <v-card>
              <v-card-title>
                Upload Files <v-spacer></v-spacer>
                <template v-if="imagesfrom != 'lead'">
                  <span
                    ><v-checkbox
                      label="is Consent"
                      v-model="isConsent"
                    ></v-checkbox
                  ></span>
                </template>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="(url, index) in urls"
                    :key="index"
                    class="d-flex child-flex"
                    cols="4"
                  >
                    <v-card>
                      <template v-if="files[index].type == 'image/jpeg'">
                        <v-img :src="url">
                          <v-app-bar flat color="rgba(0,0,0,0)">
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="deleteItem(index)"
                              color="error"
                              icon
                              fab
                            >
                              <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                          </v-app-bar>
                        </v-img>
                      </template>
                      <template v-else>
                        <v-img :src="require('@/assets/img/documents.jpg')">
                          <v-app-bar flat color="rgba(0,0,0,0)">
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="deleteItem(index)"
                              color="error"
                              icon
                              fab
                            >
                              <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                          </v-app-bar>
                        </v-img>
                      </template>

                      <p>{{ files[index].name }}</p>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  :disabled="loadingupload"
                  @click="closeDialog"
                  text
                >
                  Close
                </v-btn>
                <v-btn
                  :loading="loadingupload"
                  :disabled="loadingupload"
                  color="primary"
                  justify-end
                  text
                  @click="confirmUpload"
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="uploadDialog"
            persistent
            hide-overlay
            width="300px"
          >
            <v-card color="primary" dark>
              <v-card-text>
                Uploading files, Please Wait!
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
      </v-file-input>
      <v-spacer></v-spacer>
      <v-btn class="my-4" color="primary" small @click="getImages()"
        ><v-icon>mdi-reload</v-icon> Reload</v-btn
      >
    </div>

    <template v-if="images.length == 0 && loading">
      <div class="d-flex justify-center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </div>
    </template>
    <template v-else-if="images.length == 0 && !loading">
      <div class="noimage">
        <span class="ma-4 text-center"> No files !!</span>
      </div>
    </template>
    <template v-else>
      <div class="d-flex justify-end mx-4">
        {{ images.length == imagesProps.length ? "Files: " : "Loading...:"
        }}{{ images.length + "/" + imagesProps.length }}
      </div>
      <v-divider></v-divider>
      <v-slide-group v-model="model" center-active show-arrows>
        <v-slide-item
          v-for="(n, i) in images"
          :key="i"
          v-slot="{ active, toggle }"
        >
          <v-card
            :color="active ? 'primary' : 'white lighten-1'"
            class="mx-4 my-2"
            height="185"
            width="150"
            @click="toggle"
          >
            <card-image
              @deleted="deletedImages"
              :imageFrom="n.from"
              :imagePath="n.path"
              :url="n.url"
              :imageName="n.name"
              :uuid="uuid"
              :imagesfrom="imagesfrom"
              :originalUrl="n.originalUrl"
            />
          </v-card>
        </v-slide-item>
        <template v-if="loading">
          <v-overlay absolute opacity :value="loading">
            <div class="d-flex justify-center">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular></div
          ></v-overlay>
        </template>
      </v-slide-group>
    </template>
  </v-sheet>
</template>
<script>
import CardImage from "@/components/Images/CardImage.vue";
import { getAPI } from "@/api/axios-base";
import { apiBaseUrl } from "@/enviorment";
import axios, { AxiosError } from "axios";
import store from "@/store";
import { mapState } from "vuex";
import { notifyError } from "../Notification";

export default {
  name: "all-images-patient",
  components: { CardImage },
  props: {
    imagesfrom: String,
    uuid: String,
    imagesProps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      uploadDialog: false,
      loading: false,
      loadingupload: false,
      model: null,
      isConsent: false,
      images: [],
      dialog2: false,
      countimages: 0,
      files: [],
      urls: [],
    };
  },

  watch: {
    imagesProps() {
      this.images = [];
      this.getImages();
    },
  },
  methods: {
    deletedImages() {
      this.$emit("uploaded");
    },

    getImages() {
      const token = store.state.accessToken;
      const auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "*/*",
            };
      this.images = [];

      this.imagesProps.forEach(async (img, i) => {
        this.loading = true;
        const url = `${apiBaseUrl}/social/downloadPicture?path=${img}`;
        const nameImg = img.split("/")[3];

        await axios({
          method: "get",
          url: url,
          responseType: "blob",
          headers: auth,
        })
          .then((response) => {
            const typeFile = response.data.type;
            const buffer = new Blob([response.data], { type: typeFile });
            const file_path = URL.createObjectURL(buffer);
            this.images.push({
              url: file_path,
              path: img,
              from: "upload",
              name: nameImg,
              originalurl: `${apiBaseUrl}/social/downloadPicture?path=${img}`,
            });
          })
          .catch((error) => {
            this.loading = false;
          });
        if (i == this.imagesProps.length - 1) {
          this.loading = false;
        }
      });
      this.loading = true;
      let urlimg = "";
      if (this.imagesfrom == "lead") {
        urlimg = "/social/mediasFromLead/";
      } else {
        urlimg = "/patient/mediasFromPatient/";
      }

      getAPI
        .get(apiBaseUrl + urlimg + this.uuid)
        .then((res) => {
          this.loading = false;
          res.data.map((ima) => {
            this.images.push({ url: ima, path: "", from: "sms" });
          });
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    setImages() {
      this.urls = [];
      this.dialog2 = true;
      this.urls = this.files.map((file) => {
        return URL.createObjectURL(file);
      });
    },
    deleteItem(index) {
      this.files.splice(index, 1);
      this.urls.splice(index, 1);
    },
    closeDialog() {
      this.dialog2 = false;
      this.loadingupload = false;
      this.uploadDialog = false;
      this.isConsent = false;
      this.files = [];
      this.urls = [];
    },
    async confirmUpload() {
      this.countimages = 0;
      let url = "";
      if (this.imagesfrom == "lead") {
        url = "/social/uploadPicture/" + this.uuid;
      } else {
        url = "/patient/uploadPicture/" + this.uuid;
      }
      this.loadingupload = true;
      this.uploadDialog = true;

      for (let index = 0; index < this.files.length; index++) {
        const formDataPhotoId = new FormData();

        formDataPhotoId.append("picture", this.files[index]);
        try {
          if (this.imagesfrom == "lead") {
            await getAPI.post(url, formDataPhotoId);
          } else {
            await getAPI.post(url, formDataPhotoId, {
              params: { isConsent: this.isConsent },
            });
          }
        } catch (error) {
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        }
      }
      this.$emit("uploaded");
      this.closeDialog();
    },
  },
  mounted() {
    this.getImages();
  },
  destroyed() {
    this.images = [];
    this.model = null;
  },
};
</script>
<style lang="scss" scoped>
.noimage {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
